import { styled } from 'styled-components'

export const Wrapper = styled.div``

export const toolbarStyles = {
  justifyContent: 'space-between',
}

export const BooksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  gap: 20px;
`
