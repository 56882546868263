import React, { useCallback, useEffect } from 'react'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  IUseTaskGroupDetailsServices,
  IUseTaskGroupDetailsServicesReturn,
} from './useTaskGroupLayout.interface'
import { IGroup } from 'interfaces/groups'
import { useSwal } from 'hooks/useSwal'
import { columnConfig } from 'pages/group/gridConfigV2'
import { actionConfig } from 'pages/tasks/TaskDetails/groupsLayout/gridConfig'
import { useUserValue } from 'context/UserContext'
import { useAddGroupsToTaskService } from 'services/tasks/addGroupsToTaskService'
import { useRemoveGroupsFromTaskService } from 'services/tasks/removeGroupsFromTask'
import { ISelectAll, ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import { useFetchGroupsByTask } from 'services/tasks/getGroupsByTaskId'
import { GetGroupsByTaskFiltersEnums } from 'services/tasks/getTasksService.interface'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { debounce } from 'lodash'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { useGroupsFilterDrawer } from 'hooks/group/useGroupFilterDrawer/useGroupFilterDrawer'

const useTaskGroupLayout = ({
  refetchGroups,
  taskId,
}: IUseTaskGroupDetailsServices): IUseTaskGroupDetailsServicesReturn => {
  // states
  const [selectedItem, setSelectedItem] = useState<ISelectPage>({})
  const [state] = useUserValue()
  const [addGroupsToTask, setAddGroupsToTask] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [isFiltered, setIsFiltered] = useState(false)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    opened: false,
    filterOptions: null,
    filterValues: null,
  })
  const [searchText, setSearchText] = useState<string>('')

  // router
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const { t } = useTranslation()

  // apollo hooks
  const { addGroups } = useAddGroupsToTaskService({ taskId })
  const { removeGroups } = useRemoveGroupsFromTaskService({ taskId })

  const { groups } = useFetchGroupsByTask({
    taskId,
    groupInclusion: GetGroupsByTaskFiltersEnums.IN_GROUP,
  })

  const { setCreateDate } = useGroupsFilterDrawer({
    setFilterOptions: options => setFilterDrawer(prev => ({ ...prev, filterOptions: options })),
    refetchGroup: refetchGroups,
  })

  const handleResetClick = (): void => {
    setFilterDrawer((prev: IGroupFilterDrawerOptions) => ({
      ...prev,
      filterOptions: null,
      filterValues: null,
    }))
    setSearchText('')
    setIsFiltered(false)
    setSelectedItem({})
    setSelectAll({})
    setCreateDate([undefined, undefined])
    refetchGroups({
      currentPage,
      perPage,
      filter: null,
      taskId,
    })
  }

  const handleAddGroupsToTask = async (groupIds: string[]): Promise<void> => {
    setAddGroupsToTask(false)
    await addGroups({
      taskId,
      groupIds: groupIds,
    })
    await refetchGroups({
      taskId,
    })
  }

  const handleRemoveGroupsFromTask = (groupIds: string[]): void => {
    fireSwal({
      title:
        groupIds.length > 1
          ? t('popups.delete_groups_from_task')
          : t('popups.delete_group_from_task'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: async () => {
        await removeGroups({
          taskId,
          groupIds,
        })
        refetchGroups({
          taskId,
        })
        setSelectedItem({})
        setSelectAll({})
      },
    })
  }

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups?.length ? groups.map((n: IGroup) => n.id as string) : []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('tasks_layout.add_group')}
      color='secondary'
      onClick={(): void => setAddGroupsToTask(true)}
      icon={<AddCircleIcon />}
      background='#06C68F'
    />
  )

  // Search Submit Handler
  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const searchValue = { type: LowerCaseFilterableFieldType.MATCH, value: searchText }
    setCurrentPage(1)
    refetchGroups({
      filter: {
        name: searchValue,
      },
      currentPage: 1,
      perPage,
    })
  }

  const debouncedSearch = useCallback(
    debounce((newSearchText: string) => {
      refetchGroups({
        filter: {
          name: { type: LowerCaseFilterableFieldType.MATCH, value: newSearchText },
        },
        currentPage: 1,
        perPage,
      })
      setIsSearchType(newSearchText !== '')
    }, 500),
    [],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setSearchText(newSearchText)
    debouncedSearch(newSearchText)
  }

  const { filterOptions } = filterDrawer
  const filterIcon = isFiltered ? <RedFilterIcon /> : <FilterIcon />
  const isResetDisabled = (!filterOptions || !Object.values(filterOptions).length) && !searchText

  useEffect(() => {
    const hasActiveFilters = !!(filterOptions && Object.values(filterOptions).length > 0)
    setIsFiltered(hasActiveFilters)
  }, [filterOptions])

  const handleClick = (group: IGroup): void => {
    navigate(`/group/${group.id}`)
  }

  const config = columnConfig(handleClick, selectItem, t, true)
  const actions = actionConfig(handleRemoveGroupsFromTask, t)

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const handleChangeGroupRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetchGroups({
      filter: filterDrawer.filterOptions,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const handleGroupsPaginationClick = (_: ChangeEvent<unknown> | null, newPage: number): void => {
    setCurrentPage(newPage + 1)
    refetchGroups({
      filter: filterDrawer.filterOptions,
      currentPage: newPage + 1,
      perPage,
    })
  }

  return {
    handleAddGroupsToTask,
    handleRemoveGroupsFromTask,
    selectItem,
    selectAllItem,
    config,
    state,
    actions,
    onSearchSubmit,
    handleGroupsPaginationClick,
    handleChangeGroupRowsPerPage,
    setAddGroupsToTask,
    selectedItem,
    setSelectedItem,
    selectAll,
    setSelectAll,
    currentPage,
    setCurrentPage,
    perPage,
    selectedItemsOnPage,
    searchText,
    handleSearchChange,
    filterDrawer,
    setFilterDrawer,
    addGroupsToTask,
    handleClick,
    handleResetClick,
    createButton,
    isFiltered,
    filterIcon,
    isResetDisabled,
    isSearchType,
  }
}

export default useTaskGroupLayout
