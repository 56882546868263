import React, { useState, useEffect, FormEvent } from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_QUIZ_BY_ID } from 'gql/quiz/quiz.query'
import { GET_QUESTIONS_BY_IDS } from 'gql/questions.query'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { actionConfig } from 'pages/quizzes/gridConfig'
import {
  columnConfig,
  quizQuestionActionsConfig as questionActionsConfig,
} from 'pages/questions/gridConfig'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { useAddRemoveQuestions } from 'hooks/quizzes/useEditQuizService'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import { IQuestion } from 'interfaces/questions'
import { SelectAllMap } from 'pages/modules/ModuleDetail/QuestionList/questionList.interface'
import {} from 'pages/questions/styled-components'
import { IFilterQueryType, ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import {
  BreadcrumbItem,
  IQuizzesParams,
  IQuizzesType,
  IUseQuizDetailsReturn,
} from './useQuizDetails.interface'

const useQuizDetails = ({ currentModule }: IQuizzesParams): IUseQuizDetailsReturn => {
  const { t } = useTranslation()
  const [cards, setCards] = useState<IQuestion[]>([])

  const params = useParams()
  const { state: routerState } = useLocation()
  const navigate = useNavigate()
  const [state] = useUserValue()
  const [searchValue, setSearchValue] = useState<string>('')
  // const [editMode, setEditMode] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ISelectPage>([])
  const [selectAll, setSelectAll] = useState<SelectAllMap>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [filterData, setFilterData] = useState<IFilterQueryType>({})
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filteredQuestionList, setFilteredQuestionList] = useState<IQuestion[] | null>(null)
  const { fireSwal } = useSwal()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { data } = useQuery(GET_QUIZ_BY_ID, {
    variables: {
      quizId: params.id,
    },
  })
  const { deleteQuiz } = useDeleteQuizService()
  const { addRemoveQuestions } = useAddRemoveQuestions()
  const [fetchQuestions, { data: questionData }] = useLazyQuery(GET_QUESTIONS_BY_IDS)
  // const { editQuiz } = useEditQuizService()
  const quizData = (data && data.getQuizById) || {}

  useEffect(() => {
    if (quizData.questions && quizData.questions.length > 0) {
      fetchQuestions({
        variables: {
          ids: quizData.questions,
        },
      })
    }
  }, [quizData, quizData.questions])

  // const handleChangeRequest = async (draggedId: string, droppedId: string) => {
  //   const { data } = await changeQuestionsOrder({
  //     variables: { draggedId, droppedId },
  //   })
  // }

  useEffect(() => {
    if (cards && currentModule) {
      if (filtered) {
      } else {
        const questions = cards.filter((item: IQuestion) => item.question.toLowerCase())
        setFilteredQuestionList(questions)
      }
    }
  }, [cards, searchValue])

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const questions =
      questionData.questionsByIds.filter((item: IQuestion) => {
        return item.question.toLowerCase().includes(searchValue)
      }) || []
    setCards(questions)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setSearchValue(value)
  }

  // const editQuizDescription = (editorText: any) => {
  //   const newQuiz: any = {}
  //   newQuiz.description = editorText
  //   editQuiz(quizData.id, newQuiz, () => {})
  //   setEditMode(false)
  // }

  const handleEditDrawer = (val: IQuiz): void => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: quizData.quizType,
      id: quizData.actionId,
      data: { quizId: val.id, companyId: quizData.companyId },
    })
  }

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    const currentPageItems = cards
    const currentPageItemIds = currentPageItems.map(item => item.id)

    if (!selectAll[currentPage]) {
      setSelectedItem({
        ...selectedItem,
        [currentPage]: currentPageItemIds,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
    } else {
      setSelectedItem({
        ...selectedItem,
        [currentPage]: [],
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }
  }

  const handleDeleteQuiz = (id: string): void => {
    const ids = [id]

    fireSwal({
      title: `If you delete this Quiz it will no longer be available to use. Are you sure?`,
      confirmText: 'Yes, Delete',
      onConfirm: () => deleteQuiz(ids, () => navigate(-1)),
    })
  }

  const handleViewQuestion = (id: string): void => {
    navigate(`/questions/${id}`)
  }

  const removeQuestions = (ids: string[]): void => {
    if (ids.length > 0)
      addRemoveQuestions('remove', ids, quizData.id, () => {
        fetchQuestions({
          variables: {
            ids: quizData.questions?.filter((item: string) => !ids.includes(item)),
          },
        })
        setSelectedItem([])
      })
  }

  const addQuestions = (): void => {
    const questionIds = questionData && questionData.questionsByIds.map((i: IQuestion) => i.id)
    const quizType = quizData.quizType

    DrawerEventEmitter.emit('openDrawer', 'AddQuestionsToQuiz', true, {
      type: quizType,
      id: quizData.actionId,
      data: { quizId: quizData.id, questionIds },
    })
  }

  const handleDeleteQuestions = (ids: string[]): void => {
    const isMultiple = ids.length > 1

    const title: string = isMultiple
      ? 'If you remove Selected Questions this Questions will no longer exist in this Quiz but remains in Module Details View. Remove Questions?'
      : 'If you remove the Question this Question will no longer exist in this Quiz but remains in Module Details View. Remove the Question?'

    fireSwal({
      title,
      confirmText: 'Yes, Remove',
      onConfirm: () => removeQuestions(ids),
    })
  }

  const actionHandler = (action: string, value: IQuiz): void => {
    switch (action) {
      case 'edit':
        return handleEditDrawer(value)
      case 'delete':
        return handleDeleteQuiz(value.id as string)
      case 'view':
        return handleViewQuestion(value.id as string)
      case 'deleteQuestion':
        return handleDeleteQuestions([value.id as string])
      default:
        break
    }
  }

  const redirectToQuestion = (question: IQuestion): void => {
    navigate(`/questions/${question.id}`)
  }

  const handleResetClick = (): void => {
    setSearchValue('')
    setIsFiltered(false)
    setFiltered(false)
    setFilteredQuestionList(null)
    setSelectedItem([])
    setSelectAll({})
    setFilterData({})
    setFilterDrawerOpened(false)
    setCurrentPage(1)
    fetchQuestions({
      variables: {
        ids: quizData.questions,
      },
    })
  }

  const handleFilterClick = (filterOptions: IFilterQueryType): void => {
    setSelectedItem([])
    setSelectAll({})
    setFilterData(filterOptions)
    setIsFiltered(true)
    fetchQuestions({
      variables: {
        ids: quizData.questions,
        filter: filterOptions,
        currentPage: 1,
      },
    })
  }

  useEffect(() => {
    if (questionData) {
      const questions =
        questionData.questionsByIds.filter((item: IQuizzesType) => {
          return item.question.toLowerCase()
        }) || []
      setCards(questions)
    }
  }, [questionData, searchValue])

  const actions = actionConfig(t, actionHandler, state.userPermission)

  const questionActions = questionActionsConfig(actionHandler, state.userPermission, t)

  let breadCrumbData: BreadcrumbItem[] = []
  if (data && quizData) {
    const { quizType } = quizData
    if (quizType === 'course') {
      breadCrumbData = [
        { label: t('general.courses'), link: '/courses', icon: <Learning /> },
        {
          label: quizData.parentName,
          link: routerState?.from || `/courses/${quizData.actionId}`,
          state: routerState?.state || {},
        },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'module') {
      breadCrumbData = [
        {
          label:
            (routerState?.from && `${t('general.course')} ${t('general.modules')}`) ||
            t('general.modules'),
          link: routerState?.from || '/modules',
          state: routerState?.state || {},
          icon: <Learning />,
        },
        {
          label: quizData.parentName,
          link: `/modules/${quizData.actionId}`,
          state: routerState,
        },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'topic') {
      breadCrumbData = [
        {
          label: quizData.parentName,
          link: `/topics/${quizData.actionId}`,
          icon: <Learning />,
        },
        { label: quizData.name, link: '' },
      ]
    }

    if (quizType === 'lesson') {
      breadCrumbData = [
        {
          label: quizData.parentName,
          link: `/lessons/${quizData.actionId}`,
          icon: <Learning />,
        },
        { label: quizData.name, link: '' },
      ]
    }
  }

  const canModify = quizData.companyId === (state.selectedCompany?.id || null)
  const config = columnConfig(selectItem, t, canModify)

  return {
    breadCrumbData,
    quizData,
    canModify,
    state,
    actions,
    t,
    addQuestions,
    selectedItem,
    handleDeleteQuestions,
    isFiltered,
    setFilterDrawerOpened,
    onSearchSubmit,
    searchValue,
    handleSearchChange,
    questionActions,
    config,
    filteredQuestionList,
    cards,
    currentPage,
    selectAllItem,
    selectItem,
    redirectToQuestion,
    filterDrawerOpened,
    setIsFiltered,
    filterData,
    setFilterData,
    setFilteredQuestionList,
    setCurrentPage,
    setFiltered,
    handleResetClick,
    handleFilterClick,
  }
}

export default useQuizDetails
